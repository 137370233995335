import React from 'react';
import PageWrapper from '../components/PageWrapper';
import { Text } from '@chakra-ui/react';
import { APP_URL, URL_NAME } from '../utils/constants';

const TermsOfService = () => {
  return (
    <PageWrapper>
      <div style={{ padding: 20 }}>
        <Text mb={4} fontSize="3xl" fontWeight="bold">
          terms of service
        </Text>
        <Text fontWeight="semibold" fontSize="xl">
          1. Terms
        </Text>
        <p>
          By accessing the website at <a href={APP_URL}>{URL_NAME}</a>, you are
          agreeing to be bound by these terms of service, all applicable laws
          and regulations, and agree that you are responsible for compliance
          with any applicable local laws. If you do not agree with any of these
          terms, you are prohibited from using or accessing this site. The
          materials contained in this website are protected by applicable
          copyright and trademark law.
        </p>
        <Text fontWeight="semibold" fontSize="xl">
          2. Use License
        </Text>
        <ol type="a">
          <li>
            Permission is granted to temporarily download one copy of the
            materials (information or software) on {URL_NAME}'s website for
            personal, non-commercial transitory viewing only. This is the grant
            of a license, not a transfer of title, and under this license you
            may not:
            <ol type="i">
              <li>modify or copy the materials;</li>
              <li>
                use the materials for any commercial purpose, or for any public
                display (commercial or non-commercial);
              </li>
              <li>
                attempt to decompile or reverse engineer any software contained
                on {URL_NAME}'s website;
              </li>
              <li>
                remove any copyright or other proprietary notations from the
                materials; or
              </li>
              <li>
                transfer the materials to another person or \"mirror\" the
                materials on any other server.
              </li>
            </ol>
          </li>
          <li>
            This license shall automatically terminate if you violate any of
            these restrictions and may be terminated by {URL_NAME} at any time.
            Upon terminating your viewing of these materials or upon the
            termination of this license, you must destroy any downloaded
            materials in your possession whether in electronic or printed
            format.
          </li>
        </ol>
        <Text fontWeight="semibold" fontSize="xl">
          3. Disclaimer
        </Text>
        <ol type="a">
          <li>
            The materials on {URL_NAME}'s website are provided on an 'as is'
            basis. {URL_NAME} makes no warranties, expressed or implied, and
            hereby disclaims and negates all other warranties including, without
            limitation, implied warranties or conditions of merchantability,
            fitness for a particular purpose, or non-infringement of
            intellectual property or other violation of rights.
          </li>
          <li>
            Further, {URL_NAME} does not warrant or make any representations
            concerning the accuracy, likely results, or reliability of the use
            of the materials on its website or otherwise relating to such
            materials or on any sites linked to this site.
          </li>
        </ol>
        <Text fontWeight="semibold" fontSize="xl">
          4. Limitations
        </Text>
        <p>
          In no event shall {URL_NAME} or its suppliers be liable for any
          damages (including, without limitation, damages for loss of data or
          profit, or due to business interruption) arising out of the use or
          inability to use the materials on {URL_NAME}'s website, even if{' '}
          {URL_NAME} or a {URL_NAME} authorized representative has been notified
          orally or in writing of the possibility of such damage. Because some
          jurisdictions do not allow limitations on implied warranties, or
          limitations of liability for consequential or incidental damages,
          these limitations may not apply to you.
        </p>
        <Text fontWeight="semibold" fontSize="xl">
          5. Accuracy Of Materials
        </Text>
        <p>
          The materials appearing on {URL_NAME}'s website could include
          technical, typographical, or photographic errors. {URL_NAME} does not
          warrant that any of the materials on its website are accurate,
          complete or current. {URL_NAME} may make changes to the materials
          contained on its website at any time without notice. However{' '}
          {URL_NAME} does not make any commitment to update the materials.
        </p>
        <Text fontWeight="semibold" fontSize="xl">
          6. Links
        </Text>
        <p>
          {URL_NAME} has not reviewed all of the sites linked to its website and
          is not responsible for the contents of any such linked site. The
          inclusion of any link does not imply endorsement by {URL_NAME} of the
          site. Use of any such linked website is at the user's own risk.
        </p>
        <Text fontWeight="semibold" fontSize="xl">
          7.Modifications
        </Text>
        <p>
          {URL_NAME} may revise these terms of service for its website at any
          time without notice. By using this website you are agreeing to be
          bound by the then current version of these terms of service.
        </p>
        <Text fontWeight="semibold" fontSize="xl">
          8. Indemnification
        </Text>
        <p>
          You agree that you shall and do hereby indemnify, defend, and hold
          harmless {URL_NAME}, and
          {` ${URL_NAME}`}’s officers, directors, shareholders, employees and
          agents, and its or their successors and assigns, from and against any
          and all claims, demands, losses, costs, expenses, obligations,
          liabilities, damages, recoveries, and deficiencies, including
          interest, penalties, and reasonable legal fees and costs, that any of
          the foregoing persons or any other persons may incur or suffer and
          that result from, or are related to: (a) the use of the materials; (b)
          any breach or failure by you or anybody under your supervision or
          control with respect to your violation of terms of service or any
          other contractual obligation between you and third parties; (c) your
          or anybody under your supervision or control’s violation of any law or
          the rights of a third party, including a customer, retail partner, or
          any individual associated with a retail partner, as a result of your
          own or anybody under your supervision or control’s interaction with
          such third party; (d) any allegation that any materials that you or
          anybody under your supervision or control’s use infringe or otherwise
          violate the copyright, trademark, trade secret, or other rights of any
          third party; (e) any other activities in connection with the Services
          or your actions or those of anybody under your supervision or control.
        </p>
        <Text fontWeight="semibold" fontSize="xl">
          9. Governing Law
        </Text>
        <p>
          These terms and conditions are governed by and construed in accordance
          with the laws of United States and you irrevocably submit to the
          exclusive jurisdiction of the courts in that State or location.
        </p>
      </div>
    </PageWrapper>
  );
};

export default TermsOfService;
