import { Text } from '@chakra-ui/layout';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Select,
  Spinner,
  Stack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useLocationContext } from '../context/LocationContext';
import { useLoadingContext } from '../context/LoadingContext';
import { useUserContext } from '../context/UserContext';
import { patchUser } from '../services/UserService';
import { useTour } from 'react-rtg';
import FilterTour from '../tours/FilterTour';
import { useLoading } from '../utils/hooks';
import { useNotifyContext } from '../context/NotifyContext';
import { useTabContext } from '../context/TabContext';

const PRICES = Array(38)
  .fill(0)
  .map((item, idx) => {
    const price = item + (idx + 0) * 5;
    return { label: price ? '$' + price : 'Any', value: price };
  });

const TIMES = Array(4)
  .fill(0)
  .map((item, idx) => {
    const price = item + idx * 10;
    return {
      label: price ? price + ' min' : 'Any',
      value: price ? price - 1 : price,
    };
  });

function FilterItemInput({ options, label, userOptions, name, onInputChange }) {
  const user = useUserContext();
  const globalLoading = useLoadingContext();

  return (
    <Flex
      id={name}
      flexDir="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Text fontWeight="semibold">{label}</Text>
      <Select
        value={user?.[userOptions]?.[name]}
        onChange={onInputChange}
        name={name}
        w="125px"
        disabled={globalLoading.isLoading()}
        variant="outline"
      >
        {options.map(item => (
          <option value={item.value}>{item.label}</option>
        ))}
      </Select>
    </Flex>
  );
}

function LocationFilter({ userOptions, name, onLocationChange }) {
  const user = useUserContext();
  const { locations } = useLocationContext();

  return (
    <Box id="locations">
      <Text mb={4} fontWeight="semibold">
        Locations
      </Text>
      {locations &&
        locations.map(location => (
          <React.Fragment>
            <Flex
              pl={1}
              pt={2}
              pb={2}
              pr={4}
              alignItems="center"
              justifyContent="space-between"
            >
              <Text pr={2} textAlign="left">
                {location.label}
              </Text>
              <Checkbox
                isChecked={user?.[userOptions]?.[name]?.includes(location.id)}
                size="lg"
                onChange={() => onLocationChange(location.id)}
              />
            </Flex>
            <Divider />
          </React.Fragment>
        ))}
    </Box>
  );
}

function AdvancedFilters({ shouldBeInFilters, userOptions, locationsName }) {
  const user = useUserContext();
  const globalLoading = useLoadingContext();
  const loader = useLoading();
  const notify = useNotifyContext();
  const locations = useLoadingContext();

  const { setCurrentTab } = useTabContext();

  const [tour] = useTour('filterTour');

  useEffect(() => {
    if (shouldBeInFilters) {
      tour.open();
    }
  }, []);

  async function onLocationChange(id) {
    globalLoading.start();
    const copy = JSON.parse(JSON.stringify(user[userOptions]));

    const val = copy[locationsName];

    const idxOf = val.indexOf(id);

    if (idxOf > -1) {
      val.splice(idxOf, 1);
    } else {
      val.push(id);
    }

    copy[locationsName] = val;

    try {
      const response = await patchUser({
        userId: user.userId,
        body: { [userOptions]: copy },
      });

      await user.updateUser();
      globalLoading.stop();
      notify.success('Updated locations');
    } catch (e) {
      globalLoading.stop();
      notify.error('Error changing location');
    }
  }

  async function onInputChange(e) {
    globalLoading.start();
    const { name, value } = e.target;
    const realVal = Number(value);

    const copy = JSON.parse(JSON.stringify(user[userOptions]));
    copy[name] = realVal;

    try {
      const response = await patchUser({
        userId: user.userId,
        body: { [userOptions]: copy },
      });

      await user.updateUser();
      globalLoading.stop();

      if (shouldBeInFilters) {
        tour.next();
      }

      notify.success('Successfully updated ' + name);
    } catch (e) {
      globalLoading.stop();
      notify.error('Error updating ' + name + ' ' + e.message);
    }
  }

  async function onTourFinish() {
    try {
      loader.start();
      await patchUser({
        userId: user.userId,
        body: {
          setupComplete: 1,
        },
      });

      await user.updateUser();
      setCurrentTab(0);
      tour.close();
      loader.stop();
    } catch (e) {
      notify.error('Error completing tour');
      loader.stop();
    }
  }

  function onWaitTour() {
    console.log('CALLED');
    if (!locations || !locations.length) return true;
    return false;
  }

  return (
    <React.Fragment>
      <FilterTour
        onWait={onWaitTour}
        locations={user[userOptions][locationsName] || []}
        loader={loader}
        onTourFinish={onTourFinish}
      />
      <Stack mt={2}>
        <FilterItemInput
          label="Base Minimum Price (no tip)"
          userOptions={userOptions}
          name="minPay"
          onInputChange={onInputChange}
          options={PRICES}
        />
        <Divider />
        <FilterItemInput
          label="Time until start"
          userOptions={userOptions}
          name="timeBuffer"
          onInputChange={onInputChange}
          options={TIMES}
        />
        <Divider />
        <LocationFilter
          onLocationChange={onLocationChange}
          userOptions={userOptions}
          name={locationsName}
        />
      </Stack>
    </React.Fragment>
  );
}

export default AdvancedFilters;
